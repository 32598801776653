import React from "react";
import Icon from "@ant-design/icons";

const CkContinueCurveSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_758_29519)">
      <path
        d="M19.2501 7.67003C18.8401 7.67003 18.5001 8.00003 18.5001 8.42003V11.56C17.9901 9.99003 16.9101 8.61003 15.4001 7.59003C13.2001 6.12003 10.3801 5.59003 7.65009 6.15003C3.66009 6.97003 0.600094 9.92003 0.0600936 13.49C9.36314e-05 13.9 0.280094 14.28 0.690094 14.34C0.730094 14.34 0.770094 14.34 0.800094 14.34C1.16009 14.34 1.48009 14.08 1.54009 13.71C1.98009 10.8 4.63009 8.29003 7.97009 7.60003C10.3001 7.12003 12.7201 7.57003 14.5901 8.82003C16.0401 9.79003 16.9901 11.16 17.2801 12.68C17.2801 12.71 17.3001 12.73 17.3101 12.76H14.3601C13.9501 12.76 13.6101 13.09 13.6101 13.51C13.6101 13.93 13.9401 14.26 14.3601 14.26H19.2601C19.6701 14.26 20.0101 13.93 20.0101 13.51V8.41003C20.0101 8.00003 19.6801 7.66003 19.2601 7.66003L19.2501 7.67003Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

const CkContinueCurveIcon = (props: any) => (
  <Icon component={CkContinueCurveSvg} {...props} />
);

export default CkContinueCurveIcon;
