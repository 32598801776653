import React from "react";
import Icon from "@ant-design/icons";

const CkCaretDownSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
    <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2"/>
  </svg>
);

const CkCaretDownIcon = (props: any) => (
  <Icon component={CkCaretDownSvg} {...props} />
);

export default CkCaretDownIcon;
